// http.ts
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from "qs"
import { ElMessage } from "element-plus"
import Cookie from "js-cookie"

// 声明一个 Map 用于存储每个请求的标识 和 取消函数
const pending = new Map()
/**
 * 添加请求
 * @param {Object} config 
 */
const addPending = (config: AxiosRequestConfig) => {
   const url = [
      config.method,
      config.url,
      qs.stringify(config.params),
      qs.stringify(config.data)
   ].join('&')
   config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
      if (!pending.has(url)) { // 如果 pending 中不存在当前请求，则添加进去
         pending.set(url, cancel)
      }
   })
}
/**
 * 移除请求
 * @param {Object} config 
 */
const removePending = (config: AxiosRequestConfig) => {
   const url = [
      config.method,
      config.url,
      qs.stringify(config.params),
      qs.stringify(config.data)
   ].join('&')
   if (pending.has(url)) { // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
      const cancel = pending.get(url)
      cancel(url)
      pending.delete(url)
   }
}

/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = () => {
   for (const [url, cancel] of pending) {
      cancel(url)
   }
   pending.clear()
}

/**
 * 创建service
 */
const service = axios.create({
   baseURL: '',
   // withCredentials: true, // send cookies when cross-domain requests
   timeout: 15000 // request timeout
})

// 请求拦截器
service.interceptors.request.use((config: AxiosRequestConfig) => {
   removePending(config) // 在请求开始前，对之前的请求做检查取消操作
   addPending(config) // 将当前请求添加到 pending 中
   let token = Cookie.get('token')
   if (token) {
      if (config?.headers) {
         config.headers.Authorization = 'Bearer ' + `${token}`;
      }
   }
   return config
})

// 响应拦截器
service.interceptors.response.use((response: AxiosResponse) => {
   removePending(response) // 在请求结束后，移除本次请求
   const status = response.data.code
   let msg = response.data.message
   if (status == 400) {
      for (let i of Object.keys(response.data.data)) {
         let messages = (response.data.data)[i]
         ElMessage.error(messages[0])
      }
      return Promise.reject(false)
   }
   if (status != 200) {
      ElMessage.error(msg)
      return Promise.reject(false)
   } else {
      return Promise.resolve(response.data)
   }

})

export default service



