import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";
import ElementPlus from "element-plus";
import "./element-variables.scss";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "./plugins/rem";
import "animate.css";

createApp(App)
  .use(store)
  .use(router)
  .use(axios)
  .use(ElementPlus, { locale })
  .mount("#app");
