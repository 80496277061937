import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { clearPending } from "@/api/http"
import Container from "@/views/container.vue";
import Index from "@/views/index.vue";
import Introduction from "@/views/introduction.vue";
import HelpCenter from "@/views/help-center.vue";
import Announcement from "@/views/announcement.vue";
import News from "@/views/news.vue";
import Laws from "@/views/laws.vue";
import AnnouncementDetail from "@/views/announcement-detail.vue";
import UserCenter from "@/views/user-center.vue";
import Login from "@/views/login.vue";
import SignUp from "@/views/sign-up.vue";
import FoundPsw from "@/views/found-psw.vue";
import PartyBuildingWork from "@/views/party-building-work.vue";
import ContentDetail from "@/views/content-detail.vue";
import SignDetail from "@/views/sign-detail.vue";
import SignChange from "@/views/sign-change.vue";

const routes: Array<RouteRecordRaw> = [
   {
      path: "/",
      name: "container",
      component: Container,
      children: [
         {
            path: "",
            name: "index",
            component: Index
         },
         {
            path: "introduction",
            name: "introduction",
            component: Introduction
         },
         {
            path: "announcement",
            name: "announcement",
            component: Announcement
         },
         {
            path: "help-center",
            name: "help-center",
            component: HelpCenter
         },
         {
            path: "news",
            name: "news",
            component: News
         },
         {
            path: "laws",
            name: "laws",
            component: Laws
         },
         {
            path: "announcement-detail",
            name: "announcement-detail",
            component: AnnouncementDetail
         },
         {
            path: "user-center",
            name: "user-center",
            component: UserCenter
         },
         {
            path: "sign-up",
            name: "sign-up",
            component: SignUp
         },
         {
            path: 'found-psw',
            name: 'found-psw',
            component: FoundPsw
         }, 
         {
            path: 'party-building-work',
            name: 'party-building-work',
            component: PartyBuildingWork
         },
         {
            path: 'content-detail',
            name: 'content-detail',
            component: ContentDetail
         },
         {
            path: 'sign-detail',
            name: 'sign-detail',
            component: SignDetail
         },
         {
            path: 'sign-change',
            name: 'sign-change',
            component: SignChange
         },
      ]
   },
   {
      path: "/login",
      name: "login",
      component: Login
   }

];

const router = createRouter({
   history: createWebHashHistory(),
   routes,
});

router.beforeEach((to, from, next) => {
   //在跳转路由之前，先清除所有的请求
   clearPending()
   //页面跳转时，滚动到页面顶部
   document.body.scrollTop = 0
   // firefox
   document.documentElement.scrollTop = 0
   next()
})

export default router;
