import { ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { getTextCode } from '@/api/api'

export default function () {
  // 验证码倒计时
  let times = ref(60)
  // 是否发送了验证码
  let sends = ref(false)

  // 发送验证码事件
  const sendCode = (phone: string) => {
    if (phone) {
      getTextCodes(phone)
      let timer = setInterval(() => {
        if (times.value > 0 && times.value <= 60) {
          times.value--
        } else {
          sends.value = false
          times.value = 60
          clearInterval(timer)
        }
      }, 1000)
    } else {
      ElMessage.error('手机号必填！')
      sends.value = false
    }
  }
  // 短信接口
  const getTextCodes = (phone: string) => {
    let params = {
      phone: phone
    }
    sends.value = true
    getTextCode(params).then((res: any) => {
      sends.value = true
      ElMessage.success(res.message)
    })
  }

  // 文件格式截取
  const getFileType = computed(() => {
    return (file: string) => {
      let file_suffix = file.substring(
        file.lastIndexOf('.'),
        file.length,
      )
      return file_suffix
    }
  })

  return {
    times,
    sends,
    sendCode,
    getFileType
  }
}