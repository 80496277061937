export const spArr = (arr: [], num: number) => {
   //arr是你要分割的数组，num是以几个为一组
   let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
   for (let i = 0; i < arr.length;) {
      //注意：这里与for循环不太一样的是，没有i++
      newArr.push(arr.slice(i, (i += num)));
   }
   return newArr;
}


// 将数组分为多少组
export const spGroup= (array :[],size:number) =>{
   if(size < array.length){
      let items = Math.floor(array.length/size);
      if (items<0) {
         return  array;
      }
      let new_array = [];
      while (new_array.length != (size-1)) {
         new_array.push(array.splice(0, items));
      }
      new_array.push([...array]);
      
      return new_array;
   }
}