import { createStore } from "vuex";

export default createStore({
  state: {
    themeColor: '#0B39FE',
    fileUrl: 'https://public-oss.ydzbw.cn/',
    user: {},
    signStatus:1
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setSignStatus(state,status){
      state.signStatus = status
    }
  },
  actions: {},
  modules: {},
});
